<template>    
  <div class="d1">
    <!-- <div class="whiteTop"></div>
    <the-header :activePage="activePage"></the-header> -->
    <div class="d2" v-if="post.fields">
      <div class="d2-1">
        <h1><span style="font-weight:800">{{`${post.fields.title}`}}</span></h1>
        <h2>{{` ${post.fields.subtitle}`}}</h2>
        <div class="d5">          
          <span v-if="post.fields.author"><span class="s1">Author:</span> {{ post.fields.author }} | </span>
          <span class="s1">Category:</span> {{ post.fields.category }}  | 
          <span class="s1">Posted:</span> {{ new Date(post.sys.updatedAt).toLocaleDateString('en-US') }}
        </div>
        <img class="img1" :src="`https:${post.fields.featuredImage.fields.file.url}`" :alt="post.fields.featuredImage.fields.title">
        <div class="d6" v-if="imageSource" v-html="imageSource"></div>
        <div v-html="postBody"></div>    
        <gallery v-if="images && images.length > 0" :images="images"></gallery>  
      </div>
      <div class="d2-2" v-if="!isMobile">
        <div class="d3">
          <h3>Categories</h3>          
          <div  class="d4" v-for="(cat,index) in categories" :key="index" @click="setCategory(cat)">{{ cat }}</div>          
        </div>
        <div class="d3">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import TheHeader from '../components/layouts/TheHeader.vue'
import {client, previewClient} from '../contentful';
import { ContentfulLivePreview } from '@contentful/live-preview';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import Gallery from '../components/layouts/Gallery.vue';
import { INLINES, BLOCKS } from '@contentful/rich-text-types';
import Prism from "prismjs";
import "prismjs/themes/prism-okaidia.css"
import 'prismjs/components/prism-bash'
import 'prismjs/components/prism-javascript'
import 'prismjs/components/prism-json'
import 'prismjs/components/prism-liquid'
import 'prismjs/components/prism-markdown'
import 'prismjs/components/prism-markup-templating'
import 'prismjs/components/prism-php'
import 'prismjs/components/prism-scss'
import 'prismjs/components/prism-jsx'
import 'prismjs/plugins/line-numbers/prism-line-numbers.js'
import 'prismjs/plugins/line-numbers/prism-line-numbers.css'
import 'prismjs/plugins/toolbar/prism-toolbar.css';
import 'prismjs/plugins/toolbar/prism-toolbar.js';
import 'prismjs/plugins/show-language/prism-show-language.js';
import 'prismjs/plugins/copy-to-clipboard/prism-copy-to-clipboard.js';

export default {
  components: { Gallery  },
  data() {
    return {
      post: {},
      showHeader: false,
      scrollPosition: null,
      activePage: 'blog', 
      categories: [],
      isMobile: false       
    }
  },
  computed: {
    postBody() {
      const options = {
        renderNode: {
          'embedded-asset-block': (node) => {
            if( node.data.target && node.data.target.fields.file){
              return `<img
                src="https://${node.data.target.fields.file.url}" 
                width=70%
                alt="${node.data.target.fields.title}"
              >`  
            } else {
              return '<div>Embedded asset not found: ' + node.data.target.fields.file.url + '</div>';
            }
          },
          [INLINES.HYPERLINK]: (node) => {
            if(node.data.uri.includes('target=blank')){                            
              return (`<a href="${node.data.uri}" target="_blank">${node.content[0].value}</a>`)              
            } else if(node.data.uri.includes('youtube')){
              // var videoURL = node.data.uri;
              // var videoId = videoURL.split("embed/");
              // videoId = videoId[1].split("?")[0];              
              return (`<iframe title="YouTube video for blog post" style="aspect-ratio: 1/1; max-height: 80vh;" width="100%" src="${node.data.uri}?rel=0&amp;controls=0&amp;mute=1&amp;autoplay=1&amp;loop=1&amp;playlist=Ip7gjI2LGZM" frameborder="0" allow="autoplay; accelerometer; modestbranding; encrypted-media; web-share; gyroscope; picture-in-picture" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`)             
            }
          },
          [BLOCKS.EMBEDDED_ENTRY]: (node) => {
            if(node.data.target.sys.contentType.sys.id ==="codeBlock") {
              return `<pre class="line-numbers language-${node.data.target.fields.language}"><code>${node.data.target.fields.code}</code></pre>`              
            }                  
          }
        }
      };
      if(this.post && this.post.fields){
        return documentToHtmlString(this.post.fields.body, options);
      } else {
        return '<div></div>';
      }
    },
    imageSource() {
      if(this.post && this.post.fields){
        return documentToHtmlString(this.post.fields.imageSource);
      }
      else {
        return '<div></div>';
      }
    },
    images() {
      const imgs = [];
      if(this.post && this.post.fields && this.post.fields.images){        
        this.post.fields.images.forEach((img) => {
          imgs.push({
            src: `https:${img.fields.file.url}`,
            width: img.fields.file.details.image.width,
            height: img.fields.file.details.image.height,
            title: img.fields.title
          })
        })        
      }
      return imgs;
    },
    viewHeight() {
      return this.$store.getters.viewHeight;
    }
  },
  methods: {
    updateScroll () {
      const curPos = window.scrollY
      if (curPos < this.scrollPosition) {
        this.showHeader = true
      } else if (curPos > this.scrollPosition) {
        this.showHeader = false
      }
      this.scrollPosition = window.scrollY
    },    
    setCategory(name) {
      this.$store.dispatch('setCategory', { category: name})
      this.$router.push('/blog');
    },
    windowSize () {      
      if (window.innerWidth <= 900) {
        this.isMobile = true
      } else {
        this.isMobile = false
      }
    }
  },
  created() {
    const isPreview = this.$route.query.preview==='true';
    const slug = this.$route.params.slug;
    const contentfulCient = isPreview ? previewClient : client;
    contentfulCient
      .getEntries({ content_type: 'blogPost', 'fields.slug': slug })
      .then((response) => {
        this.post = response.items[0];     
      })
      .catch(console.error);    

    this.categories.push('All');
    contentfulCient.getEntries( {content_type: 'blogPost'}).then((response) => {
      response.items.forEach((post) => {
        if(post.fields.category && !this.categories.includes(post.fields.category)){
          this.categories.push(post.fields.category);
        }        
      });      
    });
  },
  mounted() {
    ContentfulLivePreview.init({
      locale: 'en-US',
    })
    window.addEventListener('scroll', this.updateScroll)   
    window.scrollTo({
      top:0,
      left:0,
      behavior: 'smooth'
    });
    window.addEventListener('resize', this.windowSize)
    this.windowSize()    
    this.$nextTick(() => {
      Prism.highlightAll();
    });
    
  },
  updated() {
    // Trigger Prism to highlight after DOM updates
    this.$nextTick(() => {
      Prism.highlightAll();
    });
  }
};
</script>

<style scoped>
* { 
    -moz-box-sizing: border-box; 
    -webkit-box-sizing: border-box; 
     box-sizing: border-box; 
}
h1 {
  margin:0;  
  font-weight:300;  
  font-size: 3em;
  font-family: "Playfair Display", serif;  
  border-bottom: 1px solid #363943;  
}
h3 {
  margin:0;  
  margin-bottom: 12px;
  /* font-weight:600;
  font-family: helvetica, sans-serif;
  color:rgb(255, 255, 255); */
}
h2 {
  font-weight: 200;
  font-size:2em;
  margin: 5px 0;
  font-family: "IBM Plex Sans", sans-serif;  
  /* font-family: helvetica, sans-serif; */
  /* font-family: "Playfair Display", serif; */
}
.d1 {
  background-color:rgb(86, 105, 133);
  width: 100%;
  min-height: v-bind('viewHeight');
  color:#363943;  
  font-family: "IBM Plex Sans", sans-serif;
}
.d2 {
  width: 70%;
  margin-left:15%;
  /* margin-top: 30px;     */
  padding-top: 30px;
  display: flex;
}
.d2-1 {
  /* background-color: #9EA2AF; */
  background-color: #B9BBC2;
  padding: 45px;
  margin-bottom: 30px;  
}

.d2-2{ 
  
}

.d3 {
  /* background-color: #9EA2AF; */
  background-color: #B9BBC2;
  padding: 25px;
  margin-left: 30px;
}

.d4 {
  white-space: nowrap;
  margin-bottom: 4px;
  color:#003d72;
}
.d4:hover {
  color:rgb(65, 117, 162);
  cursor:pointer;
}
.d5 {
  margin-top:7px;
  font-style:italic;
  font-family: "IBM Plex Sans", sans-serif;  
}
.iframe1{
  height:500px;  
}
.img1 {
  width:100%;
  margin-top: 40px;  
}

.s1 {
  font-weight:600;
}
.space1 {
  height:30px;
}

.theHeader {
  position: fixed;
  top: 0;
  width: calc(100% - 16px);
  border-top: 8px solid white;
  box-sizing: border-box;
  z-index: 100;
}

@media only screen and (max-width: 1600px) {
  .d2 {
    width:90%;
    margin-left:5%;
    
  }
}
@media only screen and (max-width: 900px) {
  h1 {    
    font-size: 1.8em;
    text-align: center;
  }
  h2 {
    font-size: 1.4em;
    font-weight: 300;
  }
  .d2 {
    width: 100%;
    margin: 0;
    padding: 0;
  }
  .d2-1 {    
    margin: 0;
    /* margin: 15px; */
    padding: 15px;
    /* padding: 0; */
    box-sizing:border-box;    
    width:100%;
  }
  .d7 {
    position: fixed;    
  }
}
</style>